@mixin for-phone-only {
  @media (max-width: 599px) {
    @content;
  }
}
@mixin for-tablet-landscape {
  @media (max-width: 900px) {
    @content;
  }
}
@mixin for-desktop {
  @media (max-width: 1200px) {
    @content;
  }
}
@mixin for-medium-desktop {
  @media (max-width: 1600px) {
    @content;
  }
}
@mixin for-big-desktop {
  @media (max-width: 1800px) {
    @content;
  }
}

.whatsapp-button-container {
  display: flex;
  flex-direction: row;
  align-items: center;
  position: fixed;
  bottom: 26px;
  right: 26px;
  gap: 10px;
  z-index: 1000;
}

.whatsapp-button {
  display: flex;
  align-items: center;
  justify-content: center;
  background-color: #49e670;
  border-radius: 50px;
  width: 45px;
  height: 45px;
  cursor: pointer;
}

.whatsapp-icon {
  color: white;
  font-size: 24px;
}

.whatsapp-text {
  position: relative;
  text-align: center;
  font-size: 17px;
  line-height: 21px;
  color: #333333;
  background-color: white;
  padding: 5px 15px;
  border-radius: 10px;
  box-shadow: 0 1.93465px 7.73859px rgba(0, 0, 0, 0.15);
  pointer-events: none;
  z-index: 3;
}

.whatsapp-text::before {
  content: "";
  position: absolute;
  width: 0;
  height: 0;
  border: 8px solid transparent;
  border-left: 10px solid #fff;
  top: 50%;
  right: calc(1em - 40px);
  transform: translate(-0.5em, -50%);
}

.whatsapp-text.hovered {
  color: transparent;
  padding: 5px 6px;
}

.whatsapp-text.hovered::after {
  content: "Whatsapp";
  color: #333333;
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
}

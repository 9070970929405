@import "../../assets/styles/mixins.scss";

.navbar-mobile-list {
  width: 250px;
}

.navbar__menu {
  float: left;
  padding: 5px 0;
  margin: 0;
  border-right: 1px solid #d5d5d5;

  li {
    list-style: none;
    float: left;
    margin-right: 25px;

    a,
    button {
      color: rgba(0, 0, 0, 0.87);
      font-weight: bold;
      font-size: 15px;
    }
  }

  button {
    font-size: 16px;
    color: rgba(0, 0, 0, 0.87);
    font-weight: 500;
    padding: 0;
    margin: 0;
    line-height: 19px;
    border: 0;
    margin-top: -3px;
    text-transform: none;
  }
}

.register-btn.blue-btn {
  @include for-phone-only {
    padding: 13px 13px;
  }
}
.navbar-wrapper {
  background: white;
  -webkit-box-shadow: 2px 4px 17px -9px rgba(0, 0, 0, 0.22);
  -moz-box-shadow: 2px 4px 17px -9px rgba(0, 0, 0, 0.22);
  box-shadow: 2px 4px 17px -9px rgba(0, 0, 0, 0.22);

  header {
    background: white;
  }

  img {
    float: left;
    max-width: 150px;
    margin-top: 4px;
    max-height: 53px;

    @include for-phone-only {
      max-width: 100%;
    }
  }

  .navbar__toolbar {
    height: 75px;
    padding: 0;
    display: flex;
    flex: 1 1 100%;
  }

  .navbar__title {
    display: flex;
    flex: 1 1 200px;

    @include for-phone-only {
      flex: 0 0 120px;
    }
  }

  .title-text {
    float: left;
    padding-top: 15px;
    padding-left: 15px;
    font-weight: 700;
  }

  .navbar__menu-button {
    display: flex;
    flex: 1 1 calc(100% - 200px);
    align-items: center;
    justify-content: flex-end;

    @include for-phone-only {
      flex: 0 1 calc(100% - 168px);
      float: right;
    }

    .navbar__login-btn {
      font-weight: bold;
      font-size: 15px;
      padding: 15px 20px;
      text-transform: initial;
      color: rgba(0, 0, 0, 0.87);

      @media screen and (max-width: 599px) {
        padding: 3px 7px;
        display: table;

        &:nth-child(1) {
          border-right: 1px solid #ccc;
        }
      }
    }
    .page-title {
      margin-right: 20px;
      margin-top: 4px;
    }
  }

  .menu-button button {
    display: flex;
    position: relative;
    margin-left: 0;
    font-size: 16px;
    margin-left: 20px;
    text-transform: capitalize;
  }

  .user-menu {
    display: flex;
    align-items: center;
    justify-content: center;
  }
}

@media screen and (max-width: 768px) {
  .navbar__menu {
    display: none;
  }

  .mobile-sidebar-btn {
    display: block;
  }
  .mobile-sidebar-menu {
    float: left;
    font-size: 14px;

    span {
      font-size: 14px;
    }

    button {
      padding-right: 0;
    }

    .sidemenu-btn {
      border: 0;
      background: 0;
    }

    a,
    a:visited,
    a:hover {
      color: #02457a;
      font-size: 14px;
    }

    .sub-menu-list-item {
      padding-left: 32px;
    }
  }
  .navbar-wrapper .menu-button .blue-btn {
    padding: 5px 15px;
  }

  .navbar-wrapper .title-text {
    display: none;
  }
}

.page-wrap {
  padding: 45px 20px;

  h2, h1 {
    color: #02457a;
    font-weight: 700;
    margin: 0 0 10px 0;
    margin-bottom: 40px;
    font-size: 26px;
    text-align: center;
  }
}

.form-group {
  display: flex;
  flex: 1 1 100%;
  flex-wrap: wrap;

  &.horizontal-form {
    .form-control {
      .form-label {
        flex: 1 1 80%;
        align-self: center;
        align-content: flex-end;
      }
      .form-field {
        flex: 1 1 20%;
        align-self: center;
        align-content: flex-end;
      }
    }
  }
  .form-control {
    padding: 10px 20px;
    margin-bottom: 20px;
    display: flex;
    flex: 1 1 100%;
    flex-direction: row;
    flex-wrap: wrap;

    .MuiFormControl-root {
      width: 100%;
    }

    .MuiFormHelperText-root.Mui-error {
      margin-left: 14px;
    }

    &.select-control .MuiFormLabel-root.MuiInputLabel-root {
      background: white;
      padding: 0 8px;
      margin-left: -6px;
    }

    label {
      margin-bottom: 5px;
    }
  }
}

button.blue-btn,
a.blue-btn {
  border-radius: 10px;
  border: 0;
  outline: none;
  cursor: pointer;
  font-size: 18px;
  padding: 15px 40px;
  background: rgb(27, 117, 188);
  background: linear-gradient(90deg, rgba(27, 117, 188, 1) 0%, rgba(12, 82, 138, 1) 100%);
  color: white;
  position: relative;
  
  .MuiCircularProgress-root {
    width: 25px !important;
    height: 25px !important;
    position: absolute;
    right: 8px;
    color: white;
  }
}

button.blue-btn:disabled,
a.blue-btn:disabled {
  opacity: 0.3;
  color: white;
}

button.blue-btn--small,
a.blue-btn--small {
  border-radius: 10px;
  border: 0;
  outline: none;
  cursor: pointer;
  font-size: 14px;
  padding: 10px 25px;
  background: rgb(27, 117, 188);
  color: white;
}

button.blue-btn--small:disabled,
a.blue-btn--small:disabled {
  opacity: 0.3;
  color: white;
}

button.grey-btn,
a.grey-btn {
  border-radius: 10px;
  border: 1px solid rgb(27, 117, 188);
  outline: none;
  cursor: pointer;
  font-size: 18px;
  padding: 15px 40px;
  color: rgb(27, 117, 188);
  background-color: rgba(255,255,255,0.9);

  @media (min-width: 599px) {
    padding: 15px 20px;
  }

  &:hover {
    background-color: rgba(255,255,255,1);
  }
}

button.grey-btn:disabled,
a.grey-btn:disabled {
  opacity: 0.3;
}

button.grey-btn--small,
a.grey-btn--small {
  border-radius: 10px;
  border: 1px solid rgb(27, 117, 188);
  outline: none;
  cursor: pointer;
  font-size: 14px;
  padding: 10px 25px;
  background-color: rgba(255,255,255,0.9);
  color: rgb(27, 117, 188);
  background: rgba(255,255,255,0.9);

  &:hover {
    background-color: rgba(255,255,255,1);
  }
}

button.grey-btn--small:disabled,
a.grey-btn--small:disabled {
  opacity: 0.3;
}

.mobile-sidebar-btn {
  display: none;
}

.admin-page-wrapper {
  display: flex;
  flex: 0 0 100%;
  flex-wrap: wrap;
  margin: 30px 0;

  .admin-page-breadcrumb {
    font-size: 14px;
    color: #5b5b5b;
    margin-bottom: 20px;
    width: 100%;

    a {
      font-size: 14px;
      color: #5b5b5b;
    }
    span {
      margin-left: 10px;
    }
    a.back-btn {
      float: right;
    }
  }

  .button-wrapper {
    margin: 20px 0;

    button {
      margin-right: 10px;
    }
  }

  .admin-result-header {
    display: flex;
    flex: 0 0 100%;
    justify-content: space-between;
    margin-bottom: 10px;

    h1 {
      margin-top: 0;
    }

    a {
      margin-top: 5px;
      display: table;
      float: right;
      margin-left: 10px;
    }
  }

  .admin-result-table {
    margin: 35px 0;
  }
}

.success-message-body {
  width: 100%;
  margin-bottom: 0px;
  color: #242622;
  font-size: 13px;
  text-align: center;
}

.success-message-box {
  padding: 10px;
  margin-left: 0%;
  margin-right: 0%;
  border-style: solid;
  border-radius: 2px;
  border-width: 1px !important;
  border-color: #b7eb8e !important;
  background-color: #f6ffed;
}

.warning-alert-body{
  width:100%;
  margin-bottom: 0px;
  color: #242622;
  font-size: 13px;
  text-align: center;
}

.warning-alert-box{
  padding: 10px;
  margin-left: 0%;
  margin-right: 0%;
  border-style: solid;
  border-radius: 2px;
  border-width: 1px !important;
  border-color: #ffe58e !important;
  background-color: #fffbe6;
}

.failed-alert-body {
  width: 100%;
  margin-bottom: 0px;
  color: #242622;
  font-size: 13px;
  text-align: center;
}

.failed-alert-box {
  padding: 10px;
  margin-left: 0%;
  margin-right: 0%;
  border-style: solid;
  border-radius: 2px;
  border-width: 1px !important;
  border-color: red !important;
  background-color: #fff1f0;
}

.course-master__title {
  color: white;
  font-weight: 700;
  margin: 0 auto;
  font-size: 30px;
  text-align: center;
  display: flex;
  flex: 1 1 100%;
  align-items: center;
  justify-content: center;
  background: rgba(71, 94, 251, 0.6);
  padding: 0 20px;

  h2 {
    @media (max-width: 599px) {
      font-size: 26px;
    }

    @media (max-width: 900px) {
      font-size: 28px;
    }
  }
}
.certificate{
    display: flex;
    flex-direction: column;
    align-items: center;
}
.logo{
    display: flex;
    align-self: stretch;
    justify-content: space-between;
}

.logo img{
    height: 100px;
}

.certificate .title{
    font-weight: bold;
}

// .certificate p{
//     font-size: 1rem;
//     font-family: "Roboto", "Helvetica", "Arial", sans-serif;
//     font-weight: 400;
//     line-height: 1.5;
//     letter-spacing: 0.00938em;
// }

@media print {
    .print-button {
        display: none;
    }
}
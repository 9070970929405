@import "../../assets/styles/mixins.scss";

footer {
  display: flex;
  flex-wrap: wrap;
  flex: 0 0 100%;
  background: #02457a;
  padding: 30px 0;

  .MuiContainer-root {
    display: flex;
    flex-wrap: wrap;
    flex: 0 0 100%;
  }

  .footer-content {
    display: flex;
    flex-wrap: wrap;
    flex: 0 0 100%;

    .footer-section {
      @include for-phone-only {
        flex: 0 0 100%;
      }
    }
  }

  .footer-section {
    flex: 0 0 calc(50% - 20px);
    margin: 0 10px;
    color: white;

    @include for-phone-only {
      flex: 0 0 100%;
    }

    img.footer-logo {
      max-width: 60px;
    }

    .footer-logo-wrapper {
      display: flex;
      place-items: center;

      img {
        max-width: 200px;
      }

      span {
        margin-left: 20px;

        h4 {
          margin-top: 0;
        }
      }
    }

    .fb-link {
      background: url(/assets/img/logo-fb-link.png) no-repeat;
      width: 30px;
      height: 30px;
      display: block;
      background-size: cover;
      background-position: center center;
      margin: 30px 0 30px;
    }

    a {
      margin: 0 10px;
      color: rgb(221, 215, 215);

      &:hover {
        color: white;
      }
    }

    h4 {
      margin-top: 10px;
      font-size: 18px;
    }

    h5 {
      color: #e6e6e6;
    }

    p {
      margin: 8px 0;
      color: #e6e6e6;
      
      @include for-phone-only {
        font-size: 13px;
      }
    }

    &:nth-child(3) {
      margin-top: 29px;
    }
  }

  .footer-content-bottom {
    display: flex;
    flex-wrap: wrap;
    flex: 0 0 100%;
    margin: 30px 0 0;
    font-size: 14px;

    .footer-section {
      flex: 0 0 calc(50% - 20px);

      @include for-phone-only {
        flex: 0 0 100%;
        font-size: 12px;
      }
    }
  }

  .footer-links {
    text-align: right;

    @include for-phone-only {
      text-align: left;
      margin-top: 10px;

      a {
        margin-left: 0;
        margin-right: 20px;
      }
    }
  }
}
